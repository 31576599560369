import { switchTab } from "./actions";

const initialState = 0;

const projectMembersReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case switchTab.TYPE:
      return payload.tab;
    default:
      return state;
  }
};

export default projectMembersReducer;
