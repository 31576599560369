import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore } from "modules/firebase";
import { plans, projectStatus, roles } from "utils";
import { deleteCampaign, listCampaigns } from "./campaigns";
import { deleteEvent, listEvents } from "./events";
import { deleteGuest, listGuests } from "./guests";

export const createProject = async ({ project, user }) => {
  const projectsRef = collection(firestore, "projects");

  const projectRef = await addDoc(projectsRef, {
    name: project.name,
    description: project.description,
    plan: project.plan,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    roles: {
      [user.uid]: roles.OWNER,
    },
  });

  const newProject = await getDoc(projectRef);

  const selectedPlan = plans[project.plan];

  return {
    id: newProject.id,
    ...newProject.data(),
    status: projectStatus.PENDING.value,
    usages: { guests: 0, emails: 0 },
    limits: selectedPlan.features,
  };
};

export const updateProject = async ({ projectId, fields }) => {
  const projectRef = doc(firestore, "projects", projectId);

  await updateDoc(projectRef, {
    ...fields,
    updated_at: serverTimestamp(),
  });

  return fields;
};

export const removeMemberFromProject = async ({ projectId, memberId }) => {
  await updateProject({
    projectId,
    fields: {
      [`roles.${memberId}`]: deleteField(),
    },
  });
};

export const listProjects = async ({ user }) => {
  const projectsRef = collection(firestore, "projects");

  const queryRef = query(
    projectsRef,
    where(`roles.${user.uid}`, "in", Object.values(roles)),
  );

  const querySnapshot = await getDocs(queryRef);

  return querySnapshot.docs.map((project) => ({
    id: project.id,
    ...project.data(),
  }));
};

export const retrieveProject = async ({ projectId }) => {
  const projectRef = doc(firestore, "projects", projectId);

  const projectSnapshot = await getDoc(projectRef);

  if (projectSnapshot.exists()) {
    return { id: projectSnapshot.id, ...projectSnapshot.data() };
  } else {
    throw new Error("project-retrieve/no-such-document");
  }
};

export const deleteProject = async ({ projectId }) => {
  // Delete all the projects
  const projectEvents = await listEvents({ projectId });

  for (const event of projectEvents) {
    await deleteEvent({ projectId, eventId: event.id });
  }

  // Delete all the campaigns
  const projectCampaigns = await listCampaigns({ projectId });

  for (const campaign of projectCampaigns) {
    await deleteCampaign({ projectId, campaignId: campaign.id });
  }

  // Delete all the guests
  const projectGuests = await listGuests({ projectId });

  for (const guest of projectGuests) {
    await deleteGuest({ projectId, guestId: guest.id });
  }

  // Delete the project
  const projectRef = doc(firestore, "projects", projectId);
  await deleteDoc(projectRef);

  return "success";
};

const api = {
  create: createProject,
  update: updateProject,
  removeMember: removeMemberFromProject,
  list: listProjects,
  retrieve: retrieveProject,
  delete: deleteProject,
};

export default api;
