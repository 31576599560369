import { extendTheme } from "@chakra-ui/react";

// TODO: remove it
// const palette = {
//   error: {
//     light: "#f87171",
//     main: "#ef4444",
//     dark: "#dc2626",
//   },
//   warning: {
//     light: "#fde047",
//     main: "#facc15",
//     dark: "#eab308",
//   },
//   info: {
//     light: "#eef2ff",
//     main: "#c7d2fe",
//   },
//   success: {
//     main: "#34d399",
//   },
//   neutral: {
//     main: "#d4d4d4",
//   },
// };

export const chakraTheme = extendTheme({
  initialColorMode: "system",
  useSystemColorMode: true,
  colors: {
    primary: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    secondary: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    green: {
      50: "#dffdf2",
      100: "#baf2de",
      200: "#92e9c9",
      300: "#6adfb5",
      400: "#42d6a0",
      500: "#29bd87",
      600: "#1c9369",
      700: "#0f694a",
      800: "#02402c",
      900: "#00170c",
    },
    gray1: {
      50: "#f8fafc",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cbd5e1",
      400: "#94a3b8",
      500: "#64748b",
      600: "#475569",
      700: "#334155",
      800: "#1e293b",
      900: "#0f172a",
    },
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    gray3: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#e5e5e5",
      300: "#d4d4d4",
      400: "#a3a3a3",
      500: "#737373",
      600: "#525252",
      700: "#404040",
      800: "#262626",
      900: "#171717",
    },
    gray4: {
      50: "#fafaf9",
      100: "#f5f5f4",
      200: "#e7e5e4",
      300: "#d6d3d1",
      400: "#a8a29e",
      500: "#78716c",
      600: "#57534e",
      700: "#44403c",
      800: "#292524",
      900: "#1c1917",
    },
  },
  styles: {
    global: {
      p: {
        whiteSpace: "pre-line",
      },
    },
  },
});
