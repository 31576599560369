import { Box } from "@chakra-ui/react";
import React from "react";
import { Loader } from "../Loader";

const FullpageLoader = () => {
  return (
    <Box minH="100vh">
      <Loader />
    </Box>
  );
};

export default FullpageLoader;
