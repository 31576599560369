import { combineReducers } from "redux";
import eventPageReducer from "./eventPage";
import projectMembersReducer from "./projectMembers";

const tabsReducer = combineReducers({
  eventPage: eventPageReducer,
  projectMembers: projectMembersReducer,
});

export default tabsReducer;
