import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";

export const plans = {
  EXCLUSIVE: {
    value: "EXCLUSIVE",
    name: "Exclusive",
    price: 19,
    features: { guests: 80, emails: 160 },
  },
  TRENDY: {
    value: "TRENDY",
    name: "Trendy",
    price: 39,
    features: { guests: 260, emails: 780 },
  },
  GLAM: {
    value: "GLAM",
    name: "Glam",
    price: 79,
    features: { guests: 520, emails: 1560 },
  },
};

export const projectStatus = {
  PENDING: { color: "yellow", value: "PENDING" },
  ACTIVE: { color: "green", value: "ACTIVE" },
  ERROR: { color: "red", value: "ERROR" },
  BLOCKED: { color: "red", value: "BLOCKED" },
};

export const communicationStatus = {
  PENDING: { color: "yellow", value: "PENDING" },
  READY: { color: "primary", value: "READY" },
  // SENT IS DEPRECATED
  SENT: { color: "green", value: "SENT" },
  ERROR: { color: "red", value: "ERROR" },
  BLOCKED: { color: "red", value: "BLOCKED" },
};

export const messageSendStatus = {
  SCHEDULED: {
    color: "yellow",
    value: "SCHEDULED",
    label: "label-communication-status-pending",
    icon: ClockIcon,
  },
  SENT: {
    color: "green",
    value: "SENT",
    label: "label-communication-status-sent",
    icon: CheckCircleIcon,
  },
  ERROR: {
    color: "red",
    value: "ERROR",
    label: "label-communication-status-error",
    icon: ExclamationCircleIcon,
  },
};

export const roles = {
  OWNER: 9,
  GUEST: 0,
};

export const communicationChannels = {
  EMAIL: {
    value: "EMAIL",
    plans: [plans.EXCLUSIVE.value, plans.TRENDY.value, plans.GLAM.value],
  },
  WHATSAPP: {
    value: "WHATSAPP",
    plans: [plans.TRENDY.value, plans.GLAM.value],
    badge: "Beta",
  },
};

export const formatDate = (dates) => {
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  const startDate = new Date(dates[0]);

  if (dates.length === 1) {
    return dateFormatter.format(startDate);
  }

  const endDate = new Date(dates[1]);

  return dateFormatter.formatRange(startDate, endDate);
};
