import { parse } from "csv-parse/browser/esm";

export const csvReader = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const guests = reader.result;
      parse(
        guests.trim(),
        { delimiter: [",", ";"], columns: true },
        (err, guestsList) => {
          if (err) {
            reject(err);
          }

          resolve(guestsList);
        },
      );
    };

    reader.readAsText(file);
  });

export const getFileExtension = (type) => {
  switch (type) {
    case "image/gif":
      return ".gif";
    case "image/jpeg":
    case "image/jpg":
      return ".jpeg";
    case "image/png":
      return ".png";
    case "video/mp4":
      return ".mp4";
    default:
      return "";
  }
};
